<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :info-text="''" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="d-flex justify-content-center" v-if="!submissions.length && !isSubmissionsLoading">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Simulations Found</h4>

              <p class="text-muted">No simulations has been found.</p>
            </div>
          </div>
        </div>

        <div v-else-if="isSubmissionsLoading">
          <div class="card">
            <b-skeleton-table :rows="5" :columns="6"></b-skeleton-table>
          </div>
        </div>

        <div v-else-if="submissions.length" class="card">
          <div class="card-header"></div>
          <b-table
            :fields="tableColumns"
            :items="submissions"
            :busy="isSubmissionsLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @row-clicked="onRowClicked"
          >
            <template #cell(simulation)="data">
              {{ data.item.simulation.title }}
            </template>
          </b-table>
        </div>

        <pagination
          v-if="totalSubmissions > submissions.length"
          v-model="currentPage"
          :total-rows="totalSubmissions"
          :per-page="perPage"
          @change="fetchSubmissions"
          class="mb-3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, truncate } from 'lodash';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/Ui/Pagination.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { formatFullDate } from '@/common/utils';

export default {
  components: { PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'My Submissions',
      isSubmissionsLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSubmissions: 0,
      submissions: [],
      areSimulationsLoading: false,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    tableColumns() {
      return [
        {
          key: 'simulation',
          label: 'Simulation',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        },
        { key: 'simulation.posted_by.name', label: 'Conducted By', tdClass: 'clickable-item' },
        {
          key: 'updated_at',
          label: 'Taken on',
          tdClass: 'clickable-item',
          formatter: value => (value ? formatFullDate(value) : value)
        }
      ];
    }
  },

  methods: {
    ...mapActions('simulations', ['getSimulationsSubmissions']),
    get,
    truncate,
    onRowClicked(data) {
      this.$router.push({
        name: 'student-submission-profile',
        params: { id: data.id }
      });
    },
    async fetchSubmissions(pageNum = 1, params = {}) {
      this.isSubmissionsLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getSimulationsSubmissions({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...params
      });
      this.submissions = response.data.results;
      this.currentPage = pageNum;
      this.totalSubmissions = response.data.count;
      this.isSubmissionsLoading = false;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  async mounted() {
    this.fetchSubmissions();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
