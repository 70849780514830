<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filters</div>

    <div class="sidebar-heading" v-if="[DASHBOARD_TYPES.CARECAMPUS].includes(this.getLoggedInUserDashboard)">
      Universities
    </div>
    <div class="sidebar-block" v-if="[DASHBOARD_TYPES.CARECAMPUS].includes(this.getLoggedInUserDashboard)">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="name"
          v-model="filters.uni_id"
          :reduce="uni => uni.id"
          placeholder="Select University"
          :options="allUniversities"
          @search="fetchOptions"
          :loading="areUniversitiesLoading && !allUniversities.length"
        >
          <template #list-footer>
            <li
              v-observe-visibility="visibilityChanged"
              v-show="allUniversities.length && allUniversities.length < totalUniversities"
              class="loader"
            ></li>
          </template>
          <template slot="option" slot-scope="option">
            <span :id="`uni-${option.id}`">
              {{ option.name }}
            </span>
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.name }}
          </template>
        </v-select>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Program</div>
    <div class="sidebar-block">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="filters.program"
          :reduce="offered_programs => offered_programs.id"
          placeholder="Filter program"
          :options="allPrograms"
          :loading="areProgramsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Background</div>

    <div class="sidebar-block">
      <v-select
        id="background"
        class="form-control v-select-custom"
        label="text"
        v-model="filters.background"
        :reduce="item => item.value"
        placeholder="Filter background"
        :options="backgroundOptions"
      >
      </v-select>
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';
import { truncate, get, debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { ADVISOR_BACKGROUND, DASHBOARD_TYPES } from '@/common/constants';

import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

export default {
  name: 'FiltersSidebar',
  components: { FmvSidebar },

  data() {
    return {
      filters: {
        program: null,
        background: null,
        uni_id: null,
      },
      allPrograms: [],
      areProgramsLoading: false,
      backgroundOptions: ADVISOR_BACKGROUND,
      areUniversitiesLoading: false,
      allUniversities: [],
      limit: 15,
      offset: 0,
      totalUniversities: 0,
      search: '',
      DASHBOARD_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUserDashboard']),
  },

  methods: {
    get,
    truncate,
    ...mapActions('uni', ['getAllUniversities']),
    ...mapActions('program', ['getAllPublicPrograms']),
    ...mapActions('advisors', ['updateAdvisorFilters']),

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPublicPrograms();
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    debouncedSearchTag: debounce(async (loading, search, vm) => {
      vm.search = search;
      const response = await vm.getAllUniversities({
        search,

        limit: vm.limit,
        offset: vm.offset,
      });
      loading(false);

      vm.allUniversities = [];
      vm.totalUniversities = 0;
      vm.allUniversities = vm.allUniversities.concat(response.data.results);
      vm.totalUniversities = response.data.count;
    }, 500),

    async fetchOptions(search, loading) {
      this.offset = 0;
      this.allUniversities = [];
      this.search = search;
      loading(true);
      this.debouncedSearchTag(loading, search, this);
    },

    async fetchAllUniversities() {
      if (!this.search) this.areUniversitiesLoading = true;
      try {
        const response = await this.getAllUniversities({
          ...(this.search && { search: this.search }),

          limit: this.limit,
          offset: this.offset,
        });

        this.allUniversities = this.allUniversities.concat(response.data.results);
        this.totalUniversities = response.data.count;
        this.areUniversitiesLoading = false;

        if (this.allUniversities.length > 15) {
          setTimeout(() => {
            const el = document.getElementById(`uni-${this.allUniversities.slice(-15)[0].id}`);

            if (el) {
              el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
            }
          }, 100);
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    visibilityChanged(reached) {
      if (reached) {
        this.offset += 15;
        this.fetchAllUniversities();
      }
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateAdvisorFilters({ filters });
      },
    },
  },
  async mounted() {
    this.fetchPrograms();
    this.fetchAllUniversities();
  },
};
</script>
